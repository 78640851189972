import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  dataUser = JSON.parse(`${localStorage.getItem('dataUser')}`);

  httpOptions: any = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    }),
  };
  private urlApi = environment.url_api;

  constructor(private http: HttpClient) {}

  getUserById(id: string): Observable<any> {
    const URI: string = this.urlApi + `user/${id}`;

    return this.http.get(URI, this.httpOptions);
  }

  updateProfiletUse(data: any): Observable<any> {
    const URI: string = this.urlApi + `user/${this.dataUser?.user_id}`;

    return this.http.put(URI, data);
  }

  changePassword(data: any): Observable<any> {
    const URI: string = this.urlApi + `user/change-password/${this.dataUser.user_id}`;

    return this.http.put(URI, data);
  }

  updatePhotoUser(dataUser: any): Observable<any> {
    const URI = this.urlApi + `user/avatar/${this.dataUser?.user_id}`;
    const data = this.http.put(URI, dataUser);

    return data;
  }
}
