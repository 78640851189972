export const TABLE_COLS = [
  {
    field: 'name',
    header: 'Name',
    style: 'width: 40%',
  },
  {
    field: 'status_doc',
    header: 'Status',
    style: 'width: 25%',
  },
  {
    field: 'updated_at',
    header: 'Updated At',
    style: 'width: 12.5%',
  },
  {
    field: 'created_at',
    header: 'Created At',
    style: 'width: 12.5%',
  },
];

export const TABLE_COLS_COLLABORATORS = [
  {
    field: 'name',
    header: 'Name',
    style: 'width: 200px',
  },
  {
    field: 'state',
    header: 'Status',
    style: 'width: 100px',
  },
];
